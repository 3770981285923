const BASE_URL = "https://backend-ivory-kappa.vercel.app/"
//login and profile
export const loginapi = `${BASE_URL}api/gym/login`
export const SendOtpApi = `${BASE_URL}api/gym/sendOtp`
export const verifyOtpApi = `${BASE_URL}api/gym/verifyOtp`
export const forgetPasswordapi = `${BASE_URL}api/gym/forgotPassword`
export const profileapi = `${BASE_URL}api/gym`
export const updateprofileapi = `${BASE_URL}api/gym/update`
export const updateprofileImageapi = `${BASE_URL}api/gym/update/profileImage`
export const totalcountApi = `${BASE_URL}api/gym/subadmin/count`
// theme
export const getallcolorthemeApi = `${BASE_URL}api/themeColor/gym/all`
export const selectthemeApi = `${BASE_URL}api/themeColor/gym/select`
export const selectedthemeApi = `${BASE_URL}api/themeColor/gym/selected`

// member
export const creatememberapi = `${BASE_URL}api/user/register`
export const memberapi = `${BASE_URL}api/user`
export const memberupdateapi = `${BASE_URL}api/user/update`
export const memberupdateplanapi = `${BASE_URL}api/user/updatePlan`
export const forgetpasswordapi = `${BASE_URL}api/user/forgotPassword`
export const attendanceapi = `${BASE_URL}api/attendance/month`
// plan
export const planapi = `${BASE_URL}api/plan/user/all`
export const createplanapi = `${BASE_URL}api/plan/user/create`
export const updateplanapi = `${BASE_URL}api/plan/user/update`
export const deleteplanapi = `${BASE_URL}api/plan/user`
export const paymenthistoryApi = `${BASE_URL}api/plan/userPlan/history`
// competition
export const competitionapi = `${BASE_URL}api/competition`
export const createcompetitionapi = `${BASE_URL}api/competition/create`
export const competitionupdateapi = `${BASE_URL}api/competition/update`
export const competitiondeleteapi = `${BASE_URL}api/competition/delete`
export const addprizeapi = `${BASE_URL}api/competition/prizes`
export const deleteprizeapi = `${BASE_URL}api/competition/deletePrize`
export const updatecompetitionsstatusapi = `${BASE_URL}api/competition/updateStatus`
export const createwinnerapi = `${BASE_URL}api/competition/createWinner`
export const getwinnerapi = `${BASE_URL}api/competition/winner`
export const deletewinnerapi = `${BASE_URL}api/competition/deleteWinner`

// notification
export const createnotificationforallapi = `${BASE_URL}api/notification/SubAdmin/createForAll`
export const getNotificationunreadApi = `${BASE_URL}api/notification/gym/unread`
export const deleteNotificationunreadApi = `${BASE_URL}api/notification/gym/delete`
export const verifynotificationApi = `${BASE_URL}api/notification/gym/update`

// products
export const createproductsapi = `${BASE_URL}api/product/create`
export const allproductsapi = `${BASE_URL}api/product/all`
export const productapi = `${BASE_URL}api/product`
// orders
export const allordersapi = `${BASE_URL}api/order/all`
export const orderapi = `${BASE_URL}api/order`
export const updateorderapi = `${BASE_URL}api/order/update`

// category
export const createcategoryapi = `${BASE_URL}api/category`
export const categoryapi = `${BASE_URL}api/category/all`
export const updatecategoryapi = `${BASE_URL}api/category`
export const deletecategoryapi = `${BASE_URL}api/category`
// support
export const getsupportApi = `${BASE_URL}api/support/user/all`
export const deletesupportApi = `${BASE_URL}api/support/user`
export const updatesupportApi = `${BASE_URL}api/support/user/update`
// feedback
export const getfeedbackApi = `${BASE_URL}api/feedback`
export const deletefeedbackApi = `${BASE_URL}api/feedback`
// banner
export const bannerapi = `${BASE_URL}api/banner/all`
export const createbannerapi = `${BASE_URL}api/banner/create`
export const updatebannerapi = `${BASE_URL}api/banner`
export const deletebannerapi = `${BASE_URL}api/banner`

// trainer
export const trainerapi = `${BASE_URL}api/trainer/all`
export const createtrainerapi = `${BASE_URL}api/trainer/create`
export const updatetrainerapi = `${BASE_URL}api/trainer`
export const deletetrainerapi = `${BASE_URL}api/trainer`